<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col class="main-col-margin">
        <v-card class="mx-auto">
          <v-card-title
            class="main-card-title-offset"
          >
            <v-icon large left color="white"> mdi-format-list-bulleted </v-icon>
            <span class="title">
              {{ $t('country.list.title') }}
            </span>
            <v-spacer></v-spacer>

            <v-btn icon @click="createCountry()">
              <v-icon color="white">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="countries"
            :sort-by="[]"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-1"
            :loading="isLoading"
            :loading-text="$t('country.list.loading')"
          >
            <template v-slot:[`item.controls`]="props">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="warning"
                @click="editCountry(props.item)"
              >
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="error"
                @click="openDeleteCountryDialog(props.item)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{ $t('country.list.delete.title') }}</v-card-title>
          <v-card-text>
            {{ $t('country.list.delete.description') }}
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-row class="text-center" style="margin: 0px">
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  text
                  @click="deleteCountry()"
                >
                  <v-icon dark>mdi-delete</v-icon>
                  {{ $t('country.list.delete.controlls.delete') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  text
                  @click="closeDeleteCountryDialog()"
                >
                  <v-icon dark>mdi-close-circle</v-icon>
                  {{ $t('country.list.delete.controlls.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>

<script>
export default {
  name: "ListCountries",

  data() {
    return {
      error: null,
      isLoading: true,
      headers: [
        { text: this.$t('country.list.label.iso'), align: "start", value: "iso", sortable: true },
        { text: this.$t('country.list.label.name'), value: "name", sortable: true },
        { text: this.$t('country.list.label.nicename'), value: "nicename", sortable: true },
        {
          text: this.$t('country.list.label.actions'),
          value: "controls",
          sortable: false,
          align: "center",
        },
      ],
      countries: [],
      dialog: false,
      deletingCountry: null,
    };
  },
  mounted() {
    this.fetchCountries();
  },
  methods: {
    fetchCountries() {
      this.$axios({
        method: "get",
        url: this.$globals.api.country.getAll,
      })
        .then((response) => {
          this.countries = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    createCountry() {
      this.$router.push({ name: "country/add" });
    },
    editCountry(country) {
      this.$router.push({ name: "country/edit", params: { id: country.id } });
    },
    openDeleteCountryDialog(country) {
      this.dialog = true;
      this.deletingCountry = country;
    },
    closeDeleteCountryDialog() {
      this.dialog = false;
      this.deletingCountry = null;
    },
    deleteCountry() {
      this.$axios({
        method: "post",
        url: this.$globals.api.country.delete + this.deletingCountry.id,
      })
        .then(() => {
          this.deletingCountry = null;
          this.dialog = false;
          this.isLoading = true;
          this.fetchCountries();
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  },
};
</script>
